import React, { useEffect } from "react"
import { useSocket } from "utils/socket"
import { RECIEVE_MESSAGES } from "utils/SocketEvents"

function Query() {
  const socket = useSocket()

  //   socket.on(RECIEVE_MESSAGES,)

 

  return (
    <>
        <h1>Query Page</h1>
    </>
  )
}

export default Query
