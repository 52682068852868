import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  Form,
  Input,
} from "reactstrap"
import { toast, ToastContainer } from "react-toastify"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addCategory,
  editCategory,
  getCategoriesById,
} from "services/ApiService"
import { useNavigate, useParams } from "react-router-dom"
// import { addCategory } from "services/ApiService";

const UpdateCategory = props => {
  // var navigate = useNavigate()
  //meta title
  document.title = "Update Category | Stelo - Steel Trading Dashboard "
  var id = "66f5579dd698b9fb2ffca243"
  // const { id } = useParams()
  const [categoryName, setCategoryName] = useState("Hello")
  const [innerDiameter, setInnerDiameter] = useState(false)
  const [outerDiameter, setOuterDiameter] = useState(false)
  const [length, setLength] = useState(false)
  const [heightAndWidth, setHeightAndWidth] = useState(false)

  console.log(
    categoryName,
    innerDiameter,
    outerDiameter,
    length,
    heightAndWidth
  )

  const submitHandle = () => {
    var reqObj = {
      categoryId: id,
      category: categoryName,
      innerDiameter: innerDiameter,
      outerDiameter: outerDiameter,
      length: length,
      heightAndWidth: heightAndWidth,
    }
    editCategory(reqObj).then(res => {
      console.log("update")

      window.location.href = "/categories"
      toast.success(res.msg)
      setCategoryName("")
      setDiameter(false)
      setLength(false)
      setHeightAndWidth(false)
    })
  }
  useEffect(() => {
    getCat()
  }, [id])

  const getCat = () => {
    getCategoriesById(id).then(res => {
      setCategoryName(res.data.category)
      setInnerDiameter(res.data.innerDiameter)
      setOuterDiameter(res.data.outerDiameter)
      setLength(res.data.length)
      setHeightAndWidth(res.data.heightAndWidth)
    })

    // editCategory(reqObj)
    //   .then(res => {
    //     window.location.href = "/categories"
    //     toast.success(res.msg)
    //     setCategoryName("")
    //     setDiameter(false)
    //     setLength(false)
    //     setHeightAndWidth(false)
    //   })
    //   .catch(err => {
    //     // if (err?.response?.data?.errors) {
    //     //   err?.response?.data?.errors.forEach(error => {
    //     //     console.log(error.msg)
    //     //     toast.error(error.msg)
    //     //   })
    //     // } else {
    //     //   toast.error(err?.response?.data?.message || "An error occured")
    //     // }

    //     console.log(err)
    //   })
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Update Category" />
          <Row
            className="justify-content-center align-items-center"
            style={{ minHeight: "76vh" }}
          >
            <Col xl={6} className="m-auto">
              <Card
                style={{
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#f9f9f9", // Light background color
                  borderRadius: "8px", // Rounded corners
                }}
              >
                <CardBody>
                  <Form onSubmit={submitHandle}>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Category Name
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          className="form-control"
                          value={categoryName}
                          id="horizontal-firstname-Input"
                          placeholder="Enter Category"
                          required
                          onChange={e => setCategoryName(e.target.value)}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-email-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Outer Diameter
                      </Label>
                      <Col sm={9}>
                        <div className="form-check form-switch mt-1 d-flex align-items-center">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={outerDiameter}
                            onChange={() => setOuterDiameter(!outerDiameter)}
                            style={{
                              transform: "scale(1.5)",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-email-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Inner Diameter
                      </Label>
                      <Col sm={9}>
                        <div className="form-check form-switch mt-1 d-flex align-items-center">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={innerDiameter}
                            onChange={() => setInnerDiameter(!innerDiameter)}
                            style={{
                              transform: "scale(1.5)",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-password-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Length
                      </Label>
                      <Col sm={9}>
                        <div className="form-check form-switch mt-1 d-flex align-items-center">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={length}
                            onChange={() => setLength(!length)}
                            style={{
                              transform: "scale(1.5)",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-password-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Height And Width
                      </Label>
                      <Col sm={9}>
                        <div className="form-check form-switch mt-1 d-flex align-items-center">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={heightAndWidth}
                            onChange={() => setHeightAndWidth(!heightAndWidth)}
                            style={{
                              transform: "scale(1.5)",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="justify-content-center">
                      <Col sm={9} className="d-flex justify-content-center">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UpdateCategory
