import { get } from "lodash"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { editCategory, getCategoriesById } from "services/ApiService"
import { ReactComponent as LeftSideArrow } from "../../assets/images/arrow-left-short.svg"
import { toast, ToastContainer } from "react-toastify"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  Form,
  Input,
} from "reactstrap"
function NewUpdateCat() {
  const [category, setCategory] = useState("")
  const [outerDiameter, setOuterDiameter] = useState("No")
  const [innerDiameter, setInnerDiameter] = useState("Yes")
  const [length, setLength] = useState("No")
  const [heightAndWidth, setHeightAndWidth] = useState("Yes")
  const [data, setData] = useState([])
  var navigate = useNavigate()
  var { id } = useParams()
  const handleSubmit = e => {
    e.preventDefault()
    // Handle form submission logic here
    var reqObj = {
      categoryId: id,
      category: category,
      outerDiameter: outerDiameter,
      innerDiameter: innerDiameter,
      length: length,
      heightAndWidth: heightAndWidth,
    }

    editCategory(reqObj).then(res => {
      toast.success(res.msg)
      navigate("/categories")
    })
  }

  var dataById = () => {
    getCategoriesById(id).then(res => {
      // console.log(res.data)
      setOuterDiameter(res.data.outerDiameter)
      setInnerDiameter(res.data.innerDiameter)
      setHeightAndWidth(res.data.heightAndWidth)
      setLength(res.data.length)
      setCategory(res.data.category)
    })
  }
  useEffect(() => {
    dataById()
  }, [])
  console.log(data)
  return (
    <div className="mx-3 px-3 mt-5 pt-5">
      <ToastContainer />
      <Breadcrumbs title="Categories" breadcrumbItem="Update Category" />
      <Row>
        <div className="">
          <button
            className="badge bg-primary d-inline-block font-size-11 text-center border-0 mb-2"
            style={{
              padding: "6px 16px",
              minWidth: "100px",
              borderRadius: "15px",
            }}
            onClick={() => navigate("/categories")}
          >
            <LeftSideArrow className="back-icon" /> Go Back..
          </button>
        </div>

        <Col lg={5} md={7} className="m-auto mt-5">
          <Card
            style={{
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#f9f9f9", // Light background color
              borderRadius: "8px", // Rounded corners
            }}
          >
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row className="mb-4">
                  <Label htmlFor="category" className="col-sm-3 col-form-label">
                    Category Name
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      className="form-control"
                      value={category}
                      id="category"
                      required
                      onChange={e => setCategory(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Label
                    htmlFor="outerDiameter"
                    className="col-sm-3 col-form-label"
                  >
                    Outer Diameter
                  </Label>
                  <Col sm={6}>
                    <select
                      id="outerDiameter"
                      className="form-select"
                      value={outerDiameter}
                      onChange={e => setOuterDiameter(e.target.value)}
                    >
                      <option value="true" className="">
                        Yes
                      </option>
                      <option value="false">No</option>
                    </select>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Label
                    htmlFor="innerDiameter"
                    className="col-sm-3 col-form-label"
                  >
                    Inner Diameter
                  </Label>
                  <Col sm={6}>
                    <select
                      id="innerDiameter"
                      className="form-select"
                      value={innerDiameter}
                      onChange={e => setInnerDiameter(e.target.value)}
                    >
                      <option value="true" className="">
                        Yes
                      </option>
                      <option value="false">No</option>
                    </select>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Label htmlFor="length" className="col-sm-3 col-form-label">
                    Length
                  </Label>
                  <Col sm={6}>
                    <select
                      id="length"
                      className="form-select"
                      value={length}
                      onChange={e => setLength(e.target.value)}
                    >
                      <option value="true" className="">
                        Yes
                      </option>
                      <option value="false">No</option>
                    </select>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Label
                    htmlFor="heightAndWidth"
                    className="col-sm-3 col-form-label"
                  >
                    Height and Width
                  </Label>
                  <Col sm={6}>
                    <select
                      id="heightAndWidth"
                      className="form-select"
                      value={heightAndWidth}
                      onChange={e => setHeightAndWidth(e.target.value)}
                    >
                      <option value="true" className="">
                        Yes
                      </option>
                      <option value="false">No</option>
                    </select>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col sm={9} className="d-flex justify-content-center">
                    <div>
                      <Button type="submit" color="primary" className="w-md">
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>

       
      </Row>
    </div>
  )
}

export default NewUpdateCat
