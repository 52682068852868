import React, { useEffect, useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Modal, Table, Button } from "react-bootstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { FaTrash, FaFilePdf, FaEye, FaUpload } from "react-icons/fa"

import Swal from "sweetalert2"
import { toast, ToastContainer } from "react-toastify"
import {
  addMaterials,
  deleteMaterial,
  editCategory,
  editMaterials,
  getMaterials,
  sortMaterials,
} from "services/ApiService"
import "./Material.css"
import { materialDoc_url } from "utils/APIUrls"
// import { cl } from "@fullcalendar/core/internal-common"

const Material = () => {
  const [materials, setMaterials] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [newMaterial, setNewMaterial] = useState("")
  const [searchQuery, setSearchQuery] = useState("") // State for search query

  const fetchMaterial = () => {
    getMaterials().then(res => {
      setMaterials(res.data)
    })
  }

  useEffect(() => {
    fetchMaterial()
  }, [])

  const handleOnDragEnd = result => {
    if (!result.destination) return

    const items = Array.from(materials)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setMaterials(items)

    const updatedMaterialsList = items.map((material, index) => ({
      id: material.id,
      material: material.material,
      sortKey: index,
    }))

    sortMaterials(updatedMaterialsList)
      .then(() => fetchMaterial())
      .catch(console.error)
  }

  const handleDelete = materialId => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteMaterial(materialId)
          .then(res => {
            fetchMaterial()
            toast.success(res.msg)
          })
          .catch(() =>
            Swal.fire("Error!", "Failed to delete material.", "error")
          )
      }
    })
  }

  const updateMaterials = (e, id) => {
    const file = e.target.files[0]
    // console.log("idd of materials....",id)
    // Create FormData to send file
    const formData = new FormData()
    formData.append("materialDoc", file)
    formData.append("matId", id)

    editMaterials(formData).then(res => {
      fetchMaterial()
    })
    // console.log("PDF submit", e.target.files[0])
  }

  const handleAddMaterial = () => {
    addMaterials({ material: newMaterial })
      .then(res => {
        toast.success(res.msg)
        fetchMaterial()
        setNewMaterial("")
        setShowModal(false)
      })
      .catch(console.error)
  }

  const handleSearch = e => {
    setSearchQuery(e.target.value)
  }

  const filteredMaterials = materials.filter(material =>
    material.material.toLowerCase().includes(searchQuery.toLowerCase())
  )

  console.log("Filter materialss....", filteredMaterials)

  return (
    <div className="main-div">
      <Breadcrumbs title="Dashboard" breadcrumbItem="Materials" />
      <div className="d-flex flex-row-reverse">
        <button
          className="badge bg-primary d-inline-block font-size-13 text-center border-0"
          style={{
            padding: "12px 19px",
            minWidth: "100px",
            borderRadius: "17px",
            marginRight: "20px",
          }}
          onClick={() => setShowModal(true)}
        >
          + Add Materials
        </button>
      </div>

      <div className="search-container d-flex flex-row-reverse">
        <input
          type="search"
          placeholder={`${materials.length} records...`}
          value={searchQuery}
          onChange={handleSearch}
          className="search-input" // Add a CSS class for styling
        />
      </div>

      <div className="table-div">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="materials">
            {provided => (
              <Table
                striped
                bordered
                hover
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <thead>
                  <tr>
                    <th className="font-size-13">No.</th>
                    <th className="font-size-13">Material Name</th>
                    <th className="font-size-13">Description</th>
                    <th className="font-size-13">Action</th>
                  </tr>
                </thead>
                <tbody className="font-size-13">
                  {filteredMaterials.map(
                    ({ id, material, materialDoc }, index) => (
                      <Draggable key={id} draggableId={id} index={index}>
                        {provided => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td>{index + 1}</td>
                            <td>{material}</td>
                            {materialDoc ? (
                              // <button
                              //   className="btn btn-outline-primary"
                              //   style={{
                              //     display: "inline-flex",
                              //     alignItems: "center",
                              //     padding: "8px 12px",
                              //     borderRadius: "5px",
                              //   }}
                              //   onClick={() =>
                              //     window.open(
                              //       `/path/to/${materialDoc}`,
                              //       "_blank"
                              //     )
                              //   }
                              // >
                              //   <FaFilePdf className="me-2" />
                              //   View Doc
                              // </button>
                              
                              <td>
                                <button
                                  className="btn btn-outline-primary view-doc-btn"
                                  onClick={() =>
                                    window.open(
                                      `${materialDoc_url}${materialDoc}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  <FaEye className="me-1" /> View Doc
                                </button>
                              </td>
                            ) : (
                              // <td>
                              //   <input
                              //     type="file"
                              //     // onChange={(e) => console.log("PDF submit",e.target.files[0] )}
                              //     onChange={e => updateMaterials(e, id)}
                              //   />
                              // </td>

                              <td>
                                <label
                                  htmlFor={`file-upload-${id}`}
                                  className="upload-btn"
                                >
                                  <FaUpload className="me-1" /> Upload Document
                                  <input
                                    id={`file-upload-${id}`}
                                    type="file"
                                    className="d-none" // Hide default file input
                                    onChange={e => updateMaterials(e, id)}
                                  />
                                </label>
                              </td>
                            )}
                            <td>
                              <button
                                className="btn p-0 d-inline-block font-size-20 text-center border-0 delete-button"
                                onClick={() => handleDelete(id)}
                                style={{ borderRadius: "5px" }}
                              >
                                <FaTrash />
                              </button>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </tbody>
              </Table>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder="Enter material"
            value={newMaterial}
            onChange={e => setNewMaterial(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddMaterial}>
            Add Material
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  )
}

export default Material
