import React, { useEffect } from "react"
import "./Items.css" // Import custom CSS
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { useState } from "react"
import { ReactComponent as ProcessingOrderIcon } from "../../assets/images/Order Processing.svg"
import { ReactComponent as DeliveredOrderIcon } from "../../assets/images/Delivered.svg"
import { ReactComponent as ReadyToShipOrderIcon } from "../../assets/images/Ready to ship.svg"
import { ReactComponent as ShippingOrderIcon } from "../../assets/images/Shipping.svg"
import { ReactComponent as LeftSideArrow } from "../../assets/images/arrow-left-short.svg"
import imageOrder from "../../assets/images/orderimg1.jpeg"
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  CardTitle,
  CardSubtitle,
  Container,
  UncontrolledDropdown,
  Input,
  Label,
  Form,
} from "reactstrap"
import {
  acceptOrder,
  deliveredOrder,
  editImgOrder,
  getCategories,
  getItemsDetails,
  getMaterials,
  getOrderType,
  processingOrder,
  readyToShipOrder,
  rejectOrder,
  shippingOrder,
  storeNotification,
} from "services/ApiService"
import { orderImage_url } from "utils/APIUrls"

function Items(props) {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [selectedStatus, setSelectStatus] = useState("")
  const [statusType, setStatusType] = useState(props.items[0].statusType)
  const [orderType, setOrderType] = useState(props.items[0].orderType)
  const [empty, setEmpty] = useState("")
  const [isImgOrderSubmit, setIsImgOrderSubmit] = useState(
    props.items[0].isFormDisabled
  )
  const [orderImgData, setOrderImgData] = useState([])
  const [orderName, setOrderName] = useState("")
  const [address, setAddress] = useState("")
  const [category, setCategory] = useState([])
  const [material, setMaterials] = useState([])
  const [isPickup, setIsPickUp] = useState("")
  // const [test, setIsTest] = useState("")
  const [finalItem, setFinalItem] = useState([])
  const [items, setItems] = useState({
    category: "",
    material: "",
    height: "",
    width: "",
    length: "",
    innerDiameter: "",
    outerDiameter: "",
    quantity: 1,
  })

  // console.log("testtt Typeee.......... ::::", test)
  // console.log("statuss Typeee.......... ::::", statusType)

  console.log("props category.....", props)
  // console.log("props materials.....", material[0].material)

  // var checkMap = material.map((data, index) => {
  //   console.log(data.material + index)
  // })

  {
    /* {material.map((material, index) => (
                    <option key={index} value={material.material}>
                      {material}
                    </option>
                  ))} */
  }

  var fetchImgOrderDetail = () => {
    console.log("call the img data function......")
    getItemsDetails(props.items[0].orderId).then(res => {
      setOrderImgData(res.data)
    })
  }

  useEffect(() => {
    // checkMap

    setCategory(props.categories)
    setMaterials(props.materials)
    setIsPickUp(props.items[0].isPickup)

    if (isImgOrderSubmit == 1) {
      fetchImgOrderDetail()
    }
  }, [])

  // console.log("Order Image Item Data............", orderImgData)

  var IsOrderImg = props.items[0].orderImage ? true : false
  // console.log("image order flag",IsOrderImg)

  var fetchData = () => {
    getOrderType(props.items[0].orderId).then(res => {
      setStatusType(res.data.statusType)

      // console.log("Order TYpe Dataaa........", res.data)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  // console.log("Props store data", props)

  const handleProcessing = () => {
    // console.log("order Processing start")
    processingOrder(props.items[0].orderId).then(res => {
      setStatusType(res.data.type)
      // console.log("processing type", res.data.type)
    })
  }

  const handleReadyToShip = () => {
    // console.log("order ready to ship start")
    readyToShipOrder(props.items[0].orderId).then(res => {
      setStatusType(res.data.type)
      // console.log("ready to ship type", res.data.type)
    })
  }

  const handleShipping = () => {
    // console.log("order shipping start")
    shippingOrder(props.items[0].orderId).then(res => {
      setStatusType(res.data.type)
      // console.log("shipping type", res.data.type)
    })
  }

  const handleDelivered = () => {
    // console.log("order Delivered start")
    deliveredOrder(props.items[0].orderId).then(res => {
      setStatusType(res.data.type)
      // console.log("delivered type", res.data.type)
    })
  }

  if (selectedStatus) {
    if (selectedStatus == "Processing") {
      handleProcessing()
    }
    if (selectedStatus == "Ready to Ship") {
      handleReadyToShip()
    }
    if (selectedStatus == "Shipping") {
      handleShipping()
    }
    if (selectedStatus == "Delivered") {
      handleDelivered()
    }
  }

  var handleAcceptOrder = value => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to accept this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, accept it!",
    }).then(result => {
      if (result.isConfirmed) {
        acceptOrder(props.items[0].orderId)
          .then(res => {
            storeNotification(props.items[0].orderId).then(res => {
              fetchData()
            })
            Swal.fire("Accepted!", "The order has been accepted.", "success")
          })
          .catch(err => {
            Swal.fire(
              "Error!",
              "There was an issue accepting the order.",
              "error"
            )
          })
      }
    })
  }

  // Reject Order
  var handleRejectOrder = value => {
    // Swal.fire({
    //   title: "Are you sure?",
    //   text: "Do you want to reject this order?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Yes, reject it!",
    // }).then(result => {
    //   if (result.isConfirmed) {
    //     rejectOrder(props.items[0].orderId)
    //       .then(res => {
    //         storeNotification(props.items[0].orderId).then(res => {
    //           fetchData()
    //         })
    //         Swal.fire("Rejected!", "The order has been rejected.", "success")
    //       })
    //       .catch(err => {
    //         Swal.fire(
    //           "Error!",
    //           "There was an issue rejecting the order.",
    //           "error"
    //         )
    //       })
    //   }
    // })

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to reject this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reject it!",
      input: "text", // Add input field
      inputPlaceholder: "Enter reason for cancellation",
      inputValidator: note => {
        if (!note) {
          return "You need to write something!"
        }
      },
    }).then(result => {
      if (result.isConfirmed && result.value) {
        const cancellationNote = result.value // capture the entered note
        var reqObj = {
          note: cancellationNote,
          orderId: props.items[0].orderId,
        }
        console.log("cancel OBJECt", reqObj)
        rejectOrder(reqObj)
          .then(res => {
            storeNotification(props.items[0].orderId).then(res => {
              fetchData()
            })
            Swal.fire("Rejected!", "The order has been rejected.", "success")
          })
          .catch(err => {
            Swal.fire(
              "Error!",
              "There was an issue rejecting the order.",
              "error"
            )
          })
      }
    })
  }

  const handleInputChange = e => {
    const { id, value } = e.target
    setItems(prevItems => ({
      ...prevItems,
      [id]: value,
    }))
  }

  // console.log("Item Object....", items)

  var addItem = e => {
    e.preventDefault()
    setFinalItem(prevItems => [...prevItems, items])
    setItems({
      category: "",
      material: "",
      height: "",
      width: "",
      length: "",
      innerDiameter: "",
      outerDiameter: "",
      quantity: 1,
    })
  }

  // console.log("Final item object....", finalItem)

  var handleSubmit = e => {
    e.preventDefault()
    // use this because i update the finalItem in this function but the value cant be chnaged immediately so we store that data into temporary srray and add last data into this and send to the api
    var updatedFinalItem = finalItem
    setIsImgOrderSubmit(1)

    //when i clcik on submit button then i have to add also last item data
    if (
      items.category ||
      items.material ||
      items.height ||
      items.width ||
      items.length ||
      items.innerDiameter ||
      items.outerDiameter ||
      items.quantity
    ) {
      updatedFinalItem = [...finalItem, items]
      setFinalItem(prevItems => [...prevItems, items])
    }
    // console.log(("Finalll Itemmmm ...........", updatedFinalItem))
    const reqObj = {
      order: {
        orderId: props.items[0].orderId,
        name: orderName,
        address: address,
        isFormDisabled: 1,
        items: updatedFinalItem,
      },
    }

    editImgOrder(reqObj)
      .then(res => {
        fetchImgOrderDetail()
        // console.log(res)
      })
      .catch(err => console.log(err))

    // console.log("order Create", reqObj)
    setFinalItem([])
  }

  // console.log("Finalll Itemmmm ...........", finalItem)

  return (
    <div className="items-container">
      {statusType === -1 ? (
        <div className="d-flex flex-row-reverse">
          <button
            className="badge bg-success d-inline-block font-size-11 text-center  border-0 mx-1"
            style={{
              padding: "8px 16px",
              minWidth: "50px",
              borderRadius: "15px",
            }}
            onClick={() => handleAcceptOrder()}
          >
            Accept
          </button>

          <button
            className="badge bg-danger d-inline-block font-size-11 text-center  border-0"
            style={{
              padding: "8px 16px",
              minWidth: "50px",
              borderRadius: "15px",
            }}
            onClick={() => handleRejectOrder()}
          >
            Cancel
          </button>
        </div>
      ) : (
        ""
      )}

      <button
        className="badge bg-primary d-inline-block font-size-11 text-center border-0 mb-2"
        style={{
          padding: "6px 16px",
          minWidth: "100px",
          borderRadius: "15px",
        }}
        onClick={() => window.location.reload()}
      >
        <LeftSideArrow className="back-icon" /> GO Back..
      </button>

      {/*------- Order name ,status and Order Id of that order START ------- */}
      <div className="order-info row justify-content-center mb-4">
        {/* Order Name */}
        <div className="col-12 col-md-3 text-center mb-2">
          <h5 className="order-name text-secondary">Order Name:</h5>
          <span className="order-value">{props.items[0].orderName}</span>
        </div>
        {/* Order Id */}
        <div className="col-12 col-md-3  text-center mb-2">
          <h5 className="order-id text-secondary">Order Id:</h5>
          <span className="order-value">{props.items[0].orderId}</span>
        </div>
        {/* Order Status */}
        <div className="selected-status text-center mb-4 col-12 col-md-3">
          <h5 className="order-id text-secondary mb-1">Delivery Status:</h5>
          <span className="badge px-3 py-2 bg-info font-size-14">
            {isPickup ? "PICKUP" : "DELIVERY"}
          </span>
        </div>

        {/* Order Status */}
        <div className="selected-status text-center mb-4 col-12 col-md-3">
          <h5 className="order-id text-secondary mb-1">Order Status:</h5>
          <span
            className={`badge px-3 py-2 ${
              statusType === -1
                ? "bg-warning"
                : statusType === 0
                ? "bg-danger"
                : "bg-success"
            } font-size-14 `}
          >
            {statusType === -1
              ? "Pending"
              : statusType === 0
              ? "Decline"
              : statusType === 1
              ? "Accepted"
              : statusType === 2
              ? "Processing"
              : statusType === 3
              ? "Ready To Shipped"
              : statusType === 4
              ? "Shipping"
              : statusType === 5
              ? "Delivered"
              : "Unknown status"}
          </span>
        </div>
      </div>
      {/*------- Order name ,status and Order Id of that order END ------- */}

      {/*---------- Order Status Step START ----------*/}
      {!props.items[0].isPickup ? (
        <div className="">
          {statusType == 0 ? (
            <div className="row my-4 pe-none decline-order">
              <h6 className="text-danger text-center">
                Order already canceled. Status can't be changed.
              </h6>
              <div className="d-flex justify-content-between   col-11 m-auto opacity-50 ">
                {/* Step 1: Processing */}
                {}
                <div
                  className={`rounded-circle step ${
                    selectedStatus === "Processing" ? "active" : ""
                  }`}
                  onClick={() => {
                    Swal.fire({
                      title: "Change Status",
                      text: "Are you sure you want to change the status to Processing?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, change it!",
                    }).then(result => {
                      if (result.isConfirmed) {
                        setSelectStatus("Processing")
                        Swal.fire(
                          "Updated!",
                          "Status has been changed to Processing.",
                          "success"
                        )
                      }
                    })
                  }}
                >
                  <button
                    type="button"
                    className={`btn btn-circle ${
                      statusType === 2 ||
                      statusType === 3 ||
                      statusType === 4 ||
                      statusType === 5
                        ? "btn-primary rounded-circle"
                        : "btn-secondary opacity-25 rounded-circle"
                    }`}
                  >
                    <ProcessingOrderIcon className="svgIcn1" />
                  </button>
                  <span className="step-label">Processing</span>
                </div>

                {/* Line between steps */}
                <div
                  className={`step-line flex-grow-1 mx-2 ${
                    statusType === 2 ||
                    statusType === 3 ||
                    statusType === 4 ||
                    statusType === 5
                      ? "active"
                      : ""
                  }`}
                ></div>

                {/* Step 2: Ready to Ship */}
                <div
                  className={`step ${
                    selectedStatus === "Ready to Ship" ? "active" : ""
                  }`}
                  onClick={() => {
                    Swal.fire({
                      title: "Change Status",
                      text: "Are you sure you want to change the status to Ready to Ship?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, change it!",
                    }).then(result => {
                      if (result.isConfirmed) {
                        setSelectStatus("Ready to Ship")
                        Swal.fire(
                          "Updated!",
                          "Status has been changed to Ready to Ship.",
                          "success"
                        )
                      }
                    })
                  }}
                >
                  <button
                    type="button"
                    className={`btn btn-circle ${
                      statusType === 3 || statusType === 4 || statusType === 5
                        ? "btn-primary rounded-circle"
                        : "btn-secondary opacity-25 rounded-circle"
                    }`}
                  >
                    <ReadyToShipOrderIcon className="svgIcn2" />
                  </button>
                  <span className="step-label">Ready to Ship</span>
                </div>

                <div
                  className={`step-line flex-grow-1 mx-2 ${
                    statusType === 3 || statusType === 4 || statusType === 5
                      ? "active"
                      : ""
                  }`}
                ></div>

                {/* Step 3: Shipping */}
                <div
                  className={`step ${
                    selectedStatus === "Shipping" ? "active" : ""
                  }`}
                  onClick={() => {
                    Swal.fire({
                      title: "Change Status",
                      text: "Are you sure you want to change the status to Shipping?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, change it!",
                    }).then(result => {
                      if (result.isConfirmed) {
                        setSelectStatus("Shipping")
                        Swal.fire(
                          "Updated!",
                          "Status has been changed to Shipping.",
                          "success"
                        )
                      }
                    })
                  }}
                >
                  <button
                    type="button"
                    className={`btn btn-circle ${
                      selectedStatus === "Shipping" ||
                      selectedStatus === "Delivered"
                        ? "btn-primary rounded-circle"
                        : "btn-secondary opacity-25 rounded-circle"
                    }`}
                  >
                    <ShippingOrderIcon className="svgIcn3" />
                  </button>
                  <span className="step-label">Shipping</span>
                </div>

                {/* Line between steps */}
                <div
                  className={`step-line flex-grow-1 mx-2 ${
                    selectedStatus === "Shipping" ||
                    selectedStatus === "Delivered"
                      ? "active"
                      : ""
                  }`}
                ></div>

                {/* Step 4: Delivered */}
                <div
                  className={`step ${
                    selectedStatus === "Delivered" ? "active" : ""
                  }`}
                  onClick={() => {
                    Swal.fire({
                      title: "Change Status",
                      text: "Are you sure you want to change the status to Delivered?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, change it!",
                    }).then(result => {
                      if (result.isConfirmed) {
                        setSelectStatus("Delivered")
                        Swal.fire(
                          "Updated!",
                          "Status has been changed to Delivered.",
                          "success"
                        )
                      }
                    })
                  }}
                >
                  <button
                    type="button"
                    className={`btn btn-circle ${
                      selectedStatus === "Delivered"
                        ? "btn-primary rounded-circle"
                        : "btn-secondary opacity-25 rounded-circle"
                    }`}
                  >
                    <DeliveredOrderIcon className="svgIcn4" />
                  </button>
                  <span className="step-label">Delivered</span>
                </div>

                {/* Add similar structure for "Shipping" and "Delivered" */}
                {/* ... */}
              </div>
            </div>
          ) : statusType == -1 ? (
            <div className="row   pe-none  my-4">
              <h6 className="text-danger text-center">
                This Order is still pending.Accept the order for change status .
              </h6>
              <div className="d-flex justify-content-between col-11 m-auto opacity-25">
                {/* Step 1: Processing */}
                <div
                  className={`rounded-circle step ${
                    selectedStatus === "Processing" ? "active" : ""
                  }`}
                  onClick={() => {
                    Swal.fire({
                      title: "Change Status",
                      text: "Are you sure you want to change the status to Processing?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, change it!",
                    }).then(result => {
                      if (result.isConfirmed) {
                        setSelectStatus("Processing")
                        Swal.fire(
                          "Updated!",
                          "Status has been changed to Processing.",
                          "success"
                        )
                      }
                    })
                  }}
                >
                  <button
                    type="button"
                    className={`btn btn-circle ${
                      statusType === 2 ||
                      statusType === 3 ||
                      statusType === 4 ||
                      statusType === 5
                        ? "btn-primary rounded-circle"
                        : "btn-secondary opacity-25 rounded-circle"
                    }`}
                  >
                    <ProcessingOrderIcon className="svgIcn1" />
                  </button>
                  <span className="step-label">Processing</span>
                </div>

                {/* Line between steps */}
                <div
                  className={`step-line flex-grow-1 mx-2 ${
                    statusType === 2 ||
                    statusType === 3 ||
                    statusType === 4 ||
                    statusType === 5
                      ? "active"
                      : ""
                  }`}
                ></div>

                {/* Step 2: Ready to Ship */}
                <div
                  className={`step ${
                    selectedStatus === "Ready to Ship" ? "active" : ""
                  }`}
                  onClick={() => {
                    Swal.fire({
                      title: "Change Status",
                      text: "Are you sure you want to change the status to Ready to Ship?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, change it!",
                    }).then(result => {
                      if (result.isConfirmed) {
                        setSelectStatus("Ready to Ship")
                        Swal.fire(
                          "Updated!",
                          "Status has been changed to Ready to Ship.",
                          "success"
                        )
                      }
                    })
                  }}
                >
                  <button
                    type="button"
                    className={`btn btn-circle ${
                      statusType === 3 || statusType === 4 || statusType === 5
                        ? "btn-primary rounded-circle"
                        : "btn-secondary opacity-25 rounded-circle"
                    }`}
                  >
                    <ReadyToShipOrderIcon className="svgIcn2" />
                  </button>
                  <span className="step-label">Ready to Ship</span>
                </div>

                <div
                  className={`step-line flex-grow-1 mx-2 ${
                    statusType === 3 || statusType === 4 || statusType === 5
                      ? "active"
                      : ""
                  }`}
                ></div>

                {/* Step 3: Shipping */}
                <div
                  className={`step ${
                    selectedStatus === "Shipping" ? "active" : ""
                  }`}
                  onClick={() => {
                    Swal.fire({
                      title: "Change Status",
                      text: "Are you sure you want to change the status to Shipping?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, change it!",
                    }).then(result => {
                      if (result.isConfirmed) {
                        setSelectStatus("Shipping")
                        Swal.fire(
                          "Updated!",
                          "Status has been changed to Shipping.",
                          "success"
                        )
                      }
                    })
                  }}
                >
                  <button
                    type="button"
                    className={`btn btn-circle ${
                      selectedStatus === "Shipping" ||
                      selectedStatus === "Delivered"
                        ? "btn-primary rounded-circle"
                        : "btn-secondary opacity-25 rounded-circle"
                    }`}
                  >
                    <ShippingOrderIcon className="svgIcn3" />
                  </button>
                  <span className="step-label">Shipping</span>
                </div>

                {/* Line between steps */}
                <div
                  className={`step-line flex-grow-1 mx-2 ${
                    selectedStatus === "Shipping" ||
                    selectedStatus === "Delivered"
                      ? "active"
                      : ""
                  }`}
                ></div>

                {/* Step 4: Delivered */}
                <div
                  className={`step ${
                    selectedStatus === "Delivered" ? "active" : ""
                  }`}
                  onClick={() => {
                    Swal.fire({
                      title: "Change Status",
                      text: "Are you sure you want to change the status to Delivered?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, change it!",
                    }).then(result => {
                      if (result.isConfirmed) {
                        setSelectStatus("Delivered")
                        Swal.fire(
                          "Updated!",
                          "Status has been changed to Delivered.",
                          "success"
                        )
                      }
                    })
                  }}
                >
                  <button
                    type="button"
                    className={`btn btn-circle ${
                      selectedStatus === "Delivered"
                        ? "btn-primary rounded-circle"
                        : "btn-secondary opacity-25 rounded-circle"
                    }`}
                  >
                    <DeliveredOrderIcon className="svgIcn4" />
                  </button>
                  <span className="step-label">Delivered</span>
                </div>

                {/* Add similar structure for "Shipping" and "Delivered" */}
                {/* ... */}
              </div>
            </div>
          ) : (
            <div className="row ">
              <div className="d-flex justify-content-between   my-4 col-11 m-auto">
                {/* Step 1: Processing */}
                {}
                <div
                  className={`rounded-circle step ${
                    selectedStatus === "Processing" ? "active" : ""
                  }`}
                  onClick={() => {
                    Swal.fire({
                      title: "Change Status",
                      text: "Are you sure you want to change the status to Processing?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, change it!",
                    }).then(result => {
                      if (result.isConfirmed) {
                        setSelectStatus("Processing")
                        Swal.fire(
                          "Updated!",
                          "Status has been changed to Processing.",
                          "success"
                        )
                      }
                    })
                  }}
                >
                  <button
                    type="button"
                    className={`btn btn-circle ${
                      statusType === 2 ||
                      statusType === 3 ||
                      statusType === 4 ||
                      statusType === 5
                        ? "btn-primary opacity-100 rounded-circle"
                        : "btn-secondary opacity-25 rounded-circle"
                    }`}
                  >
                    <ProcessingOrderIcon className="svgIcn1" />
                  </button>
                  <span className="step-label">Processing</span>
                </div>

                {/* Line between steps */}
                <div
                  className={`step-line flex-grow-1 mx-2  ${
                    statusType === 2 ||
                    statusType === 3 ||
                    statusType === 4 ||
                    statusType === 5
                      ? "active"
                      : ""
                  }`}
                ></div>

                {/* Step 2: Ready to Ship */}
                <div
                  className={`step ${
                    selectedStatus === "Ready to Ship" ? "active" : ""
                  }`}
                  onClick={() => {
                    Swal.fire({
                      title: "Change Status",
                      text: "Are you sure you want to change the status to Ready to Ship?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, change it!",
                    }).then(result => {
                      if (result.isConfirmed) {
                        setSelectStatus("Ready to Ship")
                        Swal.fire(
                          "Updated!",
                          "Status has been changed to Ready to Ship.",
                          "success"
                        )
                      }
                    })
                  }}
                >
                  <button
                    type="button"
                    className={`btn btn-circle ${
                      statusType === 3 || statusType === 4 || statusType === 5
                        ? "btn-primary rounded-circle"
                        : "btn-secondary opacity-25 rounded-circle"
                    }`}
                  >
                    <ReadyToShipOrderIcon className="svgIcn2" />
                  </button>
                  <span className="step-label">Ready to Ship</span>
                </div>

                <div
                  className={`step-line flex-grow-1 mx-2 ${
                    statusType === 3 || statusType === 4 || statusType === 5
                      ? "active"
                      : ""
                  }`}
                ></div>

                {/* Step 3: Shipping */}
                <div
                  className={`step ${statusType === 4 ? "active" : ""}`}
                  onClick={() => {
                    Swal.fire({
                      title: "Change Status",
                      text: "Are you sure you want to change the status to Shipping?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, change it!",
                    }).then(result => {
                      if (result.isConfirmed) {
                        setSelectStatus("Shipping")
                        Swal.fire(
                          "Updated!",
                          "Status has been changed to Shipping.",
                          "success"
                        )
                      }
                    })
                  }}
                >
                  <button
                    type="button"
                    className={`btn btn-circle ${
                      statusType === 4 || statusType === 5
                        ? "btn-primary rounded-circle"
                        : "btn-secondary opacity-25 rounded-circle"
                    }`}
                  >
                    <ShippingOrderIcon className="svgIcn3" />
                  </button>
                  <span className="step-label">Shipping</span>
                </div>

                {/* Line between steps */}
                <div
                  className={`step-line flex-grow-1 mx-2 ${
                    statusType === 4 || statusType === 5 ? "active" : ""
                  }`}
                ></div>

                {/* Step 4: Delivered */}
                <div
                  className={`step ${statusType === 5 ? "active" : ""}`}
                  onClick={() => {
                    Swal.fire({
                      title: "Change Status",
                      text: "Are you sure you want to change the status to Delivered?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, change it!",
                    }).then(result => {
                      if (result.isConfirmed) {
                        setSelectStatus("Delivered")
                        Swal.fire(
                          "Updated!",
                          "Status has been changed to Delivered.",
                          "success"
                        )
                      }
                    })
                  }}
                >
                  <button
                    type="button"
                    className={`btn btn-circle ${
                      statusType === 5
                        ? "btn-primary rounded-circle"
                        : "btn-secondary opacity-25 rounded-circle"
                    }`}
                  >
                    <DeliveredOrderIcon className="svgIcn4" />
                  </button>
                  <span className="step-label">Delivered</span>
                </div>

                {/* Add similar structure for "Shipping" and "Delivered" */}
                {/* ... */}
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {/*---------- Order Status Step END ----------*/}

      {/* Items Details Shows  START */}
      {orderType === 1 ? (
        <div className="table-responsive ">
          {props.items[0].notes ? (
            <div className="">
              <span className="note-title">Note : </span>
              <span className="note-body w-100">{props.items[0].notes}</span>
            </div>
          ) : (
            ""
          )}
          <table className="table table-div table-hover table-striped align-middle">
            <thead className="table-header">
              <tr>
                <th>Category</th>
                <th>Material</th>
                <th>Inner Diameter</th>
                <th>Outer Diameter</th>
                <th>Width</th>
                <th>Height</th>
                <th>Length</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {props.items.map((item, indx) => (
                <tr key={indx}>
                  <td>{item.category}</td>
                  <td>{item.material}</td>
                  <td>
                    {item.innerDiameter ? `${item.innerDiameter} mm` : "-"}
                  </td>
                  <td>
                    {item.outerDiameter ? `${item.outerDiameter} mm` : "-"}
                  </td>
                  <td>{item.width ? `${item.width} mm` : "-"}</td>
                  <td>{item.height ? `${item.height} mm` : "-"}</td>
                  <td>{item.length ? `${item.length} mm` : "-"}</td>
                  <td>{item.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : orderType === 2 ? (
        <div className="row d-flex justify-content-center  ">
          <div className="col-lg-6 col-md orderimg-div  d-flex justify-content-center position-relative ">
            <div className="note-text ">
              <span className="note-title">Note : </span>
              <span className="note-body w-100">{props.items[0].note}</span>
            </div>
            <img
              className="orderImg  "
              src={`${orderImage_url}${props.items[0].orderImage}`}
              alt=""
              loading="lazy"
            />
          </div>

          {isImgOrderSubmit ? (
            <div className="col-lg-6 ">
              <div className="table-responsive ">
                <table className="table table-div table-hover table-striped align-middle img-order-div">
                  <thead className="table-header">
                    <tr>
                      <th>Category</th>
                      <th>Material</th>
                      <th>Inner Diameter</th>
                      <th>Outer Diameter</th>
                      <th>Width</th>
                      <th>Height</th>
                      <th>Length</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderImgData.map((item, indx) => (
                      <tr key={indx}>
                        <td>{item.category}</td>
                        <td>{item.material}</td>
                        <td>
                          {item.innerDiameter
                            ? `${item.innerDiameter} mm`
                            : "-"}
                        </td>
                        <td>
                          {item.outerDiameter
                            ? `${item.outerDiameter} mm`
                            : "-"}
                        </td>
                        <td>{item.width ? `${item.width} mm` : "-"}</td>
                        <td>{item.height ? `${item.height} mm` : "-"}</td>
                        <td>{item.length ? `${item.length} mm` : "-"}</td>
                        <td>{item.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="col-lg-6  form-div p-4">
              <h4 className="text-center mb-4 text-primary">Order Details</h4>

              <form>
                {/* <!-- Order Name Field --> */}
                <div className="mb-3">
                  <label htmlFor="orderName" className="form-label">
                    Order Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="orderName"
                    placeholder="Enter order name"
                    disabled={isImgOrderSubmit}
                    onChange={e => setOrderName(e.target.value)}
                  />
                </div>

                <div className="item-form">
                  <div className="mb-3">
                    <label htmlFor="category" className="form-label">
                      Category
                    </label>
                    <select
                      className="form-select"
                      id="category"
                      onChange={handleInputChange}
                      value={items.category}
                      disabled={isImgOrderSubmit}
                    >
                      <option value="" selected disabled>
                        Select a category
                      </option>
                      {category.map((data, index) => {
                        return (
                          <option key={index} value={data.id}>
                            {data.category}
                          </option>
                        )
                      })}
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="material" className="form-label">
                      Material
                    </label>
                    <select
                      className="form-select"
                      id="material"
                      onChange={handleInputChange}
                      value={items.material}
                      disabled={isImgOrderSubmit}
                    >
                      <option value="" selected disabled>
                        Select material
                      </option>

                      {material.map((data, index) => {
                        return (
                          <option key={index} value={data.material}>
                            {data.material}
                          </option>
                        )
                      })}
                    </select>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="height" className="form-label">
                        Height
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="height"
                        placeholder="Enter height"
                        onChange={handleInputChange}
                        value={items.height}
                        disabled={isImgOrderSubmit}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label htmlFor="width" className="form-label">
                        Width
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="width"
                        placeholder="Enter width"
                        onChange={handleInputChange}
                        value={items.width}
                        disabled={isImgOrderSubmit}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label htmlFor="innerDiameter" className="form-label">
                        Inner Diameter
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="innerDiameter"
                        placeholder="Enter inner diameter"
                        onChange={handleInputChange}
                        value={items.innerDiameter}
                        disabled={isImgOrderSubmit}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label htmlFor="length" className="form-label">
                        Outer Diameter
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="outerDiameter"
                        placeholder="Enter length"
                        onChange={handleInputChange}
                        value={items.outerDiameter}
                        disabled={isImgOrderSubmit}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label htmlFor="length" className="form-label">
                        Length
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="length"
                        placeholder="Enter length"
                        onChange={handleInputChange}
                        value={items.length}
                        disabled={isImgOrderSubmit}
                      />
                    </div>
                  </div>

                  {/* <!-- Quantity Field with Increment/Decrement --> */}
                  <div className="mb-3">
                    <label htmlFor="quantity" className="form-label">
                      Quantity
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="quantity"
                      min="1"
                      placeholder="Enter quantity"
                      onChange={handleInputChange}
                      value={items.quantity}
                      disabled={isImgOrderSubmit}
                    />
                  </div>

                  {/* <!-- Add Items Button --> */}
                  <div className="d-flex flex-row-reverse  ">
                    <button
                      type="submit"
                      className=" add-item-btn"
                      onClick={addItem}
                      disabled={isImgOrderSubmit}
                    >
                      + Add Item
                    </button>
                  </div>

                  {/* <!-- Submit Button --> */}
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                      disabled={props.items[0].isFormDisabled}
                    >
                      Submit Order
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      ) : (
        <div className="notes-container">
          <div className="note-title-note-order">NOTE</div>
          <div className="note-content">
            {props.items[0].note}
          </div>
        </div>
      )}

      {/* Items Details Shows  END */}
    </div>
  )
}

export default Items

/*  

    selectedStatus && ( statusType == -1 ? "pending" : statusType == 0 ? "Decline" :  statusType == 1 ?"Accepted" : statusType == 2 ? "Processing":statusType == 3 ?"Ready To Shipped":statusType == 4?"Shipping":statusType == 5?"Delivered":"unknown status")                                     

cy = current year 2024   sun 
ey = expexted year 2027 wed

  if(ey > cy){
    ey-cy = num

    day + 3 = expted_day
    return expted_day
  }else{
    cy-ey = num

    day - 2 = eday
    return eday
  }

*/

// GCVL7645488
